import { GroupsOutlined, CalendarMonth, Troubleshoot, CurrencyExchange } from '@mui/icons-material';
const icons = {
    GroupsOutlined,
    CalendarMonth,
    Troubleshoot,
    CurrencyExchange
};

const gestion = {
    id: 'gestion',
    title: 'Gestion',
    type: 'group',
    children: [
        {
            id: 'patients',
            title: 'Patients',
            type: 'item',
            url: '/patients',
            icon: icons.GroupsOutlined,
            target: false
        },
        {
            id: 'appointments',
            title: 'Rendez-vous',
            type: 'item',
            url: '/appointments',
            icon: icons.CalendarMonth,
            target: false
        },
        {
            id: 'records',
            title: 'Diagnostic',
            type: 'item',
            url: '/records',
            icon: icons.Troubleshoot,
            target: false
        },
        {
            id: 'bills',
            title: 'Tarifs',
            type: 'item',
            url: '/bills',
            icon: icons.CurrencyExchange,
            target: false
        },

    ]
};

export default gestion;