import { lazy } from 'react';
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import AddUser from 'pages/users/AddUser';
import EditUser from 'pages/users/EditUser';
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));
const UsersList = Loadable(lazy(() => import('../pages/users/UsersLists')));
const NotFound = Loadable(lazy(() => import('../pages/errors/NotFound')));
const AddPatient = Loadable(lazy(() => import('../pages/patients/AddPatient')));
const PatientList = Loadable(lazy(() => import('../pages/patients/PatientsList')));
const EditPatient = Loadable(lazy(() => import('../pages/patients/EditPatient')));
const AppointmentList = Loadable(lazy(() => import('../pages/appointments/AppointmentList')));
const AddAppointment = Loadable(lazy(() => import('../pages/appointments/AddAppointment')));
const EditAppointment = Loadable(lazy(() => import('../pages/appointments/EditAppointment')));
const AddRecord = Loadable(lazy(() => import('../pages/records/AddRecord')));
const RedordsList = Loadable(lazy(() => import('../pages/records/RecordsList')));
const EditRecord = Loadable(lazy(() => import('../pages/records/EditRecord')));
const AddPrix = Loadable(lazy(() => import('../pages/prix/AddPrix')));
const PrixList = Loadable(lazy(() => import('../pages/prix/PrixList')));
const EditPrix= Loadable(lazy(() => import('../pages/prix/EditPrix')));
const ProgAppointmentList = Loadable(lazy(() => import('../pages/appointments/ProgAppointments')));
const Profile = Loadable(lazy(() => import('../pages/profile/Profile')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'users',
      element: <UsersList />
    },
    {
      path: 'users/add',
      element: <AddUser />
    },
    {
      path: 'users/update/:id',
      element: <EditUser />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: 'patients/add',
      element: <AddPatient />
    },
    {
      path: 'patients',
      element: <PatientList />
    },
    {
      path: 'patients/update/:id',
      element: <EditPatient />
    },
    {
      path: 'appointments',
      element: <AppointmentList />
    },
    {
      path: 'appointments/add',
      element: <AddAppointment />
    },
    {
      path: 'appointments/update/:id',
      element: <EditAppointment />
    },
    {
      path: 'appointments/pend',
      element: <ProgAppointmentList />
    },
    {
      path: 'records',
      element: <RedordsList />
    },
    {
      path: 'records/add',
      element: <AddRecord />
    },
    {
      path: 'records/update/:id',
      element: <EditRecord />
    },
    {
      path: 'bills',
      element: <PrixList />
    },
    {
      path: 'bills/add',
      element: <AddPrix />
    },
    {
      path: 'bills/update/:id',
      element: <EditPrix />
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]
};

export default MainRoutes;
