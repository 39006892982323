import {
    CREATE_APPOINTMENT_SUCCESS,
    CREATE_APPOINTMENT_FAILURE,
    UPDATE_APPOINTMENT_SUCCESS,
    UPDATE_APPOINTMENT_FAILURE,
    GET_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT_FAILURE,
    DELETE_APPOINTMENT_SUCCESS,
    DELETE_APPOINTMENT_FAILURE,
    GET_ALL_APPOINTMENTS_SUCCESS,
    GET_ALL_APPOINTMENTS_FAILURE,
    GET_PROG_APPOINTMENTS_SUCCESS,
    GET_PROG_APPOINTMENTS_FAILURE,
} from '../../actions/types';

const initialState = {
    appointment: null,
    appointments: [],
    appointmentsProg: [],
    error: null,
};

const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointment: action.payload,
                error: null,
            };
        case CREATE_APPOINTMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointment: action.payload,
                error: null,
            };
        case UPDATE_APPOINTMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointment: action.payload,
                error: null,
            };
        case GET_APPOINTMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointment: null,
                error: null,
            };
        case DELETE_APPOINTMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointments: action.payload,
                error: null,
            };
        case GET_ALL_APPOINTMENTS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_PROG_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointmentsProg: action.payload,
                error: null,
            };
        case GET_PROG_APPOINTMENTS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default appointmentReducer;