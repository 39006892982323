import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, updateUser } from '../../actions/UserActions';
import {
    TextField,
    Typography,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
    Button,
    Grid,
    Snackbar,
    Alert,
    Box,
} from '@mui/material';
import AccessDenied from '../errors/AccessDenied';
import { decodeId } from '../../Crypte';
import { useParams } from "react-router-dom";

const EditUser = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const userId = decodeId(id);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [cin, setCin] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [dateRecrutement, setDateRecrutement] = useState("");
    const [adresse, setAdresse] = useState("");
    const [telephone, setTelephone] = useState("");
    const [selectedRole, setSelectedRole] = useState(null);
    const user = useSelector((state) => state.users.user);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    useEffect(() => {
        dispatch(fetchUserData(userId));
    }, [dispatch, userId]);

    useEffect(() => {
        if (user) {
            setUsername(user?.username);
            setEmail(user?.email);
            setCin(user?.cin);
            setNom(user?.nom);
            setDateRecrutement(user?.date_recrutement)
            setPrenom(user?.prenom);
            setAdresse(user?.adresse);
            setTelephone(user?.telephone);
            setSelectedRole(user.role);
        }
    }, [user]);

    const handleUpdate = () => {
        const updatedUser = {
            username,
            cin,
            email,
            nom,
            prenom,
            date_recrutement: dateRecrutement,
            adresse,
            telephone,
            role: selectedRole,
        };

        dispatch(updateUser(userId, updatedUser))
            .then(() => {
                openSnackbar("User updated successfully", "success");
            })
            .catch((error) => {
                openSnackbar("Failed to update user", "error");
                console.log(error);
            });
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            {(currentUser && ((currentUser?.role ==="Administrateurs") || (currentUser.permissions.includes("Ajouter des utilisateurs")))) ? (
                <div>
                    <Box sx={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginBottom: '1rem',
                    }} >
                        {/* <PersonAddIcon sx={{ margin: '0.5rem' }} fontSize='large' /> */}
                        <Typography
                            variant="h4"
                            className='fw-bold'
                            sx={{
                                // color: '#fff',
                                margin: '0.5rem',

                            }}
                            color='primary'
                            mb={3}
                            textAlign={'center'}
                        >
                            Modifier l'utilisateur: {user?.nom}&nbsp;{user?.prenom}
                        </Typography>
                    </Box>
                    <form onSubmit={handleUpdate} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    
                                    
                                    variant='standard'
                                    label="Username"
                                    name="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="CIN"
                                    name="cin"
                                    value={cin}
                                    onChange={(e) => setCin(e.target.value)}
                                    fullWidth
                                    
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Nom"
                                    name="nom"
                                    value={nom}
                                    onChange={(e) => setNom(e.target.value)}
                                    fullWidth
                                    
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Prenom"
                                    name="prenom"
                                    value={prenom}
                                    onChange={(e) => setPrenom(e.target.value)}
                                    fullWidth
                                    
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                    
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Adresse"
                                    name="adresse"
                                    value={adresse}
                                    onChange={(e) => setAdresse(e.target.value)}
                                    fullWidth
                                    
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Date Recrutement"
                                    name="date_recrutement"
                                    type="date"
                                    value={dateRecrutement}
                                    onChange={(e) => setDateRecrutement(e.target.value)}
                                    fullWidth
                                    
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Telephone"
                                    name="telephone"
                                    value={telephone}
                                    onChange={(e) => setTelephone(e.target.value)}
                                    fullWidth
                                    
                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography>Poste</Typography>
                                <FormControl>
                                    <RadioGroup name="roleId" value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
                                    <FormControlLabel value={'Administrateurs'} control={<Radio />} label={'Administrateurs'} />
                                        <FormControlLabel value={'Docteur'} control={<Radio />} label={'Docteur'} />
                                        <FormControlLabel value={'Infirmier'} control={<Radio />} label={'Infirmier'} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained">
                                    Modifier
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </div>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
}

export default EditUser;