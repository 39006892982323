import {
    CREATE_PRIX_SUCCESS,
    CREATE_PRIX_FAILURE,
    UPDATE_PRIX_SUCCESS,
    UPDATE_PRIX_FAILURE,
    GET_PRIX_SUCCESS,
    GET_PRIX_FAILURE,
    DELETE_PRIX_SUCCESS,
    DELETE_PRIX_FAILURE,
    GET_ALL_PRIX_SUCCESS,
    GET_ALL_PRIX_FAILURE,
    GET_PATIENT_PRIX_SUCCESS,
    GET_PATIENT_PRIX_FAILURE,
} from '../../actions/types';

const initialState = {
    prix: null,
    patientPrix: [],
    prixs: [],
    error: null,
};

const prixReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PRIX_SUCCESS:
            return {
                ...state,
                prix: action.payload,
                error: null,
            };
        case CREATE_PRIX_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_PRIX_SUCCESS:
            return {
                ...state,
                prix: action.payload,
                error: null,
            };
        case UPDATE_PRIX_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_PRIX_SUCCESS:
            return {
                ...state,
                prix: action.payload,
                error: null,
            };
        case GET_PRIX_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_PRIX_SUCCESS:
            return {
                ...state,
                prix: null,
                error: null,
            };
        case DELETE_PRIX_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_PRIX_SUCCESS:
            return {
                ...state,
                prixs: action.payload,
                error: null,
            };
        case GET_ALL_PRIX_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_PATIENT_PRIX_SUCCESS:
            return {
                ...state,
                patientPrix: action.payload,
                error: null,
            };
        case GET_PATIENT_PRIX_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default prixReducer;