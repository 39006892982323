import { useDispatch, useSelector } from "react-redux";
import MainLayout from '../layout/MainLayout';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import UsersList from '../pages/users/UsersLists';

const UsersRoutes = () => {
    const { user: currentUser } = useSelector((state) => state.auth);

    return (
        <MainLayout>
            <Routes>
                <Route path="/users/list" element={<UsersList />} />
            </Routes>
        </MainLayout>
    )
};

export default UsersRoutes;