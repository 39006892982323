import { combineReducers } from 'redux';
import auth from './authReducers';
import menu from './menu';
import usersReducers from './usersReducers';
import patientReducer from './patientsReducers';
import appointmentReducer from './appointmentReducers';
import recordReducer from './recordsReducers';
import prixReducer from './prixReducers';
import fileReducer from './filesReducers';

const rootReducer = combineReducers({
    files: fileReducer,
    prix: prixReducer,
    records: recordReducer,
    appointments: appointmentReducer,
    patients: patientReducer,
    users: usersReducers,
    menu,
    auth,
});

export default rootReducer;

// import { combineReducers } from 'redux';

// import menu from './menu';


// const reducers = combineReducers({ menu });

// export default reducers;
