import {
    CREATE_RECORD_SUCCESS,
    CREATE_RECORD_FAILURE,
    UPDATE_RECORD_SUCCESS,
    UPDATE_RECORD_FAILURE,
    GET_RECORD_SUCCESS,
    GET_RECORD_FAILURE,
    DELETE_RECORD_SUCCESS,
    DELETE_RECORD_FAILURE,
    GET_ALL_RECORDS_SUCCESS,
    GET_ALL_RECORDS_FAILURE,
    GET_PATIENT_RECORDS_SUCCESS,
    GET_PATIENT_RECORDS_FAILURE,
} from '../../actions/types';

const initialState = {
    record: null,
    records: [],
    patientRecords: [],
    error: null,
};

const recordReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_RECORD_SUCCESS:
            return {
                ...state,
                record: action.payload,
                error: null,
            };
        case CREATE_RECORD_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_RECORD_SUCCESS:
            return {
                ...state,
                record: action.payload,
                error: null,
            };
        case UPDATE_RECORD_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_RECORD_SUCCESS:
            return {
                ...state,
                record: action.payload,
                error: null,
            };
        case GET_RECORD_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_RECORD_SUCCESS:
            return {
                ...state,
                record: null,
                error: null,
            };
        case DELETE_RECORD_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_RECORDS_SUCCESS:
            return {
                ...state,
                records: action.payload,
                error: null,
            };
        case GET_ALL_RECORDS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_PATIENT_RECORDS_SUCCESS:
            return {
                ...state,
                patientRecords: action.payload,
                error: null,
            };
        case GET_PATIENT_RECORDS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default recordReducer;