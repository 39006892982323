import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import users from './users';
import gestion from './patients';
const menuItems = {
  items: [dashboard, users, 
    gestion
    // utilities, support
  ]
};

export default menuItems;
