import {
    CREATE_PATIENT_SUCCESS,
    CREATE_PATIENT_FAILURE,
    UPDATE_PATIENT_SUCCESS,
    UPDATE_PATIENT_FAILURE,
    GET_PATIENT_SUCCESS,
    GET_PATIENT_FAILURE,
    DELETE_PATIENT_SUCCESS,
    DELETE_PATIENT_FAILURE,
    GET_ALL_PATIENTS_SUCCESS,
    GET_ALL_PATIENTS_FAILURE
} from '../../actions/types';

const initialState = {
    patient: null,
    patients: [],
    error: null,
};

const patientReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PATIENT_SUCCESS:
            return {
                ...state,
                patient: action.payload,
                error: null,
            };
        case CREATE_PATIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_PATIENT_SUCCESS:
            return {
                ...state,
                patient: action.payload,
                error: null,
            };
        case UPDATE_PATIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_PATIENT_SUCCESS:
            return {
                ...state,
                patient: action.payload,
                error: null,
            };
        case GET_PATIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_PATIENT_SUCCESS:
            return {
                ...state,
                patient: null,
                error: null,
            };
        case DELETE_PATIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_PATIENTS_SUCCESS:
            return {
                ...state,
                patients: action.payload,
                error: null,
            };
        case GET_ALL_PATIENTS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default patientReducer;