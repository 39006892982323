import Routes from './routes';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import { useDispatch, useSelector } from "react-redux";
// import { Routes as DomRoutes, Route } from 'react-router-dom';
// const AuthLogin = Loadable(lazy(() => import('./pages/authentication/Login')));
// import MinimalLayout from './layout/MinimalLayout';
import AuthRoutes from './routes/indexLogin';
import UsersRoutes from 'routes/usersRoutes';
// import UsersList from './pages/users/UsersLists';
// import MainLayout from './layout/MainLayout';
const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  return (
    <>
      <ThemeCustomization>
        <ScrollTop>
          {currentUser ? (
            <>
              <Routes />
              {/* <UsersList /> */}
            </>
          ) : (
            <>
              {/* <Route path="/register/user" element={<Register />} /> */}
              {/* <Route path="*" element={<AuthLogin /> Children={}} /> */}
              <AuthRoutes />
            </>
          )}
        </ScrollTop>
      </ThemeCustomization>
    </>
  )
};

export default App;


// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//     </div>
//   );
// }

// export default App;
