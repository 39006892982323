import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/index';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(),
  devTools: process.env.NODE_ENV !== 'production', 
});

export default store;

// import { configureStore } from '@reduxjs/toolkit';

// import reducers from './reducers';


// const store = configureStore({
//   reducer: reducers
// });

// const { dispatch } = store;

// export { store, dispatch };
