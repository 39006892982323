import API_URL from './apiUrl';

class AppointmentServices {
    createAppointment(data) {
        return API_URL.post("/appointments/add", data);
    };

    getAllAppointments() {
        return API_URL.get("/appointments");
    };

    getAppointment(appointmentId) {
        return API_URL.get(`/appointments/appointment/${appointmentId}`);
    };

    updateAppointment(appointmentId, data) {
        return API_URL.put(`/appointments/update/${appointmentId}`, data);
    };

    deleteAppointment(appointmentId) {
        return API_URL.delete(`/appointments/delete/${appointmentId}`);
    };

    getProgAppointments() {
        return API_URL.get("/appointments/status/programmed");
    };

}

export default new AppointmentServices();