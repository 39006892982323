export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

// ------------------------- MESSAGE  --------------------------
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// ------------------------- Patient  --------------------------
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_FAILURE = 'CREATE_PATIENT_FAILURE';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILURE = 'UPDATE_PATIENT_FAILURE';
export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
export const GET_PATIENT_FAILURE = 'GET_PATIENT_FAILURE';
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS';
export const DELETE_PATIENT_FAILURE = 'DELETE_PATIENT_FAILURE';
export const GET_ALL_PATIENTS_SUCCESS = 'GET_ALL_PATIENTS_SUCCESS';
export const GET_ALL_PATIENTS_FAILURE = 'GET_ALL_PATIENTS_FAILURE';

// ------------------------- Patient  --------------------------
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE';
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_FAILURE = 'GET_APPOINTMENT_FAILURE';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAILURE = 'DELETE_APPOINTMENT_FAILURE';
export const GET_ALL_APPOINTMENTS_SUCCESS = 'GET_ALL_APPOINTMENTS_SUCCESS';
export const GET_ALL_APPOINTMENTS_FAILURE = 'GET_ALL_APPOINTMENTS_FAILURE';
export const GET_PROG_APPOINTMENTS_SUCCESS = 'GET_PROG_APPOINTMENTS_SUCCESS';
export const GET_PROG_APPOINTMENTS_FAILURE = 'GET_PROG_APPOINTMENTS_FAILURE';

// ------------------------- RECORD  --------------------------
export const CREATE_RECORD_SUCCESS = 'CREATE_RECORD_SUCCESS';
export const CREATE_RECORD_FAILURE = 'CREATE_RECORD_FAILURE';
export const UPDATE_RECORD_SUCCESS = 'UPDATE_RECORD_SUCCESS';
export const UPDATE_RECORD_FAILURE = 'UPDATE_RECORD_FAILURE';
export const GET_RECORD_SUCCESS = 'GET_RECORD_SUCCESS';
export const GET_RECORD_FAILURE = 'GET_RECORD_FAILURE';
export const DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS';
export const DELETE_RECORD_FAILURE = 'DELETE_RECORD_FAILURE';
export const GET_ALL_RECORDS_SUCCESS = 'GET_ALL_RECORDS_SUCCESS';
export const GET_ALL_RECORDS_FAILURE = 'GET_ALL_RECORDS_FAILURE';
export const GET_PATIENT_RECORDS_SUCCESS = 'GET_PATIENT_RECORDS_SUCCESS';
export const GET_PATIENT_RECORDS_FAILURE = 'GET_PATIENT_RECORDS_FAILURE';

// ------------------------- PRIX  --------------------------
export const CREATE_PRIX_SUCCESS = 'CREATE_PRIX_SUCCESS';
export const CREATE_PRIX_FAILURE = 'CREATE_PRIX_FAILURE';
export const UPDATE_PRIX_SUCCESS = 'UPDATE_PRIX_SUCCESS';
export const UPDATE_PRIX_FAILURE = 'UPDATE_PRIX_FAILURE';
export const GET_PRIX_SUCCESS = 'GET_PRIX_SUCCESS';
export const GET_PRIX_FAILURE = 'GET_PRIX_FAILURE';
export const DELETE_PRIX_SUCCESS = 'DELETE_PRIX_SUCCESS';
export const DELETE_PRIX_FAILURE = 'DELETE_PRIX_FAILURE';
export const GET_ALL_PRIX_SUCCESS = 'GET_ALL_PRIX_SUCCESS';
export const GET_ALL_PRIX_FAILURE = 'GET_ALL_PRIX_FAILURE';
export const GET_PATIENT_PRIX_SUCCESS = 'GET_PATIENT_PRIX_SUCCESS';
export const GET_PATIENT_PRIX_FAILURE = 'GET_PATIENT_PRIX_FAILURE';


// ----------------------- FILE TYPES --------------------------
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAILURE = 'GET_FILES_FAILURE';
