import React, { useEffect, useState } from "react";
import { Box, IconButton, Link, useMediaQuery } from '@mui/material';
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import { fetchProgAppointments } from 'actions/AppointmentActions';
import { useDispatch, useSelector } from "react-redux";

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const appointments = useSelector((state) => state.appointments.appointmentsProg);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProgAppointments());
  }, [dispatch]);

  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      <Notification count={appointments?.length || 0} appointments={appointments || []} />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
