// import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';
import { Groups } from '@mui/icons-material'
const icons = {
  Groups,
};

const users = {
  id: 'users',
  title: 'Utilisateurs',
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'Utilisateurs',
      type: 'item',
      url: '/users',
      icon: icons.Groups,
      target: false
    },

  ]
};

export default users;