import AppointmentServices from '../services/appointmentServices';
import {
    CREATE_APPOINTMENT_SUCCESS,
    CREATE_APPOINTMENT_FAILURE,
    UPDATE_APPOINTMENT_SUCCESS,
    UPDATE_APPOINTMENT_FAILURE,
    GET_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT_FAILURE,
    DELETE_APPOINTMENT_SUCCESS,
    DELETE_APPOINTMENT_FAILURE,
    GET_ALL_APPOINTMENTS_SUCCESS,
    GET_ALL_APPOINTMENTS_FAILURE,
    GET_PROG_APPOINTMENTS_SUCCESS,
    GET_PROG_APPOINTMENTS_FAILURE,
} from './types';

export const createAppointment = (appointmentData) => {
    return (dispatch) => {
        return AppointmentServices.createAppointment(appointmentData)
            .then((response) => {
                dispatch({
                    type: CREATE_APPOINTMENT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_APPOINTMENT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const fetchAllAppointments = () => {
    return (dispatch) => {
        return AppointmentServices.getAllAppointments()
            .then((response) => {
                dispatch({
                    type: GET_ALL_APPOINTMENTS_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_APPOINTMENTS_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const fetchProgAppointments = () => {
    return (dispatch) => {
        return AppointmentServices.getProgAppointments()
            .then((response) => {
                dispatch({
                    type: GET_PROG_APPOINTMENTS_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: GET_PROG_APPOINTMENTS_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};


export const updateAppointment = (appointmentId, updatedAppointment) => {
    return (dispatch) => {
        return AppointmentServices.updateAppointment(appointmentId, updatedAppointment)
            .then((response) => {
                dispatch({
                    type: UPDATE_APPOINTMENT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_APPOINTMENT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const fetchAppointment = (appointmentId) => {
    return (dispatch) => {
        return AppointmentServices.getAppointment(appointmentId)
            .then((response) => {
                dispatch({
                    type: GET_APPOINTMENT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: GET_APPOINTMENT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const deleteAppointment = (appointmentId) => {
    return (dispatch) => {
        return AppointmentServices.deleteAppointment(appointmentId)
            .then((response) => {
                dispatch({
                    type: DELETE_APPOINTMENT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_APPOINTMENT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};
